<template>
  <div>
    <!-- <lottie
      v-if="!business"
      :options="defaultOptions"
      :height="300"
      :width="400"
      v-on:animCreated="handleAnimation"
    /> -->

    <div v-if="business">
      <v-overlay class="loading-center" :value="save">
        <!-- <lottie
          :options="defaultOptions"
          :height="300"
          :width="400"
          v-on:animCreated="handleAnimation"
        /> -->
        <v-progress-circular
          :width="3"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>

      <v-container fluid>
        <v-row class="pa-0 ma-0">
          <h1>
            Información
            <span class="font-weight-light">General</span>
          </h1>
        </v-row>
      </v-container>

      <div class="main-container">
        <v-container fluid class="screen-width fill-height">
          <v-row class="pa-0 ma-0">
            <v-col cols="12">
              <v-img
                :src="business.cover.original"
                :lazy-src="business.cover.loading"
                aspect-ratio="1"
                class="grey lighten-2 cover-up"
                max-width="100%"
                max-height="250"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-img
                :src="business.logo.original"
                :lazy-src="business.logo.loading"
                aspect-ratio="1"
                class="grey lighten-2 logo-up"
                max-width="150"
                max-height="150"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-row justify="end" v-if="$store.state.user.type == 'sudo'">
                <v-col cols="6" md="3">
                  <v-menu offset-y right rounded="xl">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        :loading="imageDownloadLoading"
                        @click="exit = !exit"
                      >
                        Descargar
                        <v-icon right dark v-if="!exit">
                          fas fa-chevron-down
                        </v-icon>
                        <v-icon right dark v-else> fas fa-chevron-up </v-icon>
                      </v-btn>
                    </template>
                    <v-list rounded>
                      <v-list-item-group color="primary">
                        <v-list-item v-if="business.logo.original">
                          <v-list-item-title>
                            <a @click.prevent="downloadImage(business, 'logo')">
                              Logo
                            </a>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item v-if="business.cover.original">
                          <v-list-item-title>
                            <a
                              @click.prevent="downloadImage(business, 'cover')"
                            >
                              Cover
                            </a>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>

              <v-card
                class="text-left flex-grow-1"
                :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'white'"
                elevation="0"
                style="border-radius: 17px !important; overflow: hidden"
              >
                <v-card-text class="text--primary">
                  <v-container fluid class="fill-height">
                    <v-row
                      v-if="$store.state.user.type != 'sudo'"
                      class="pa-0 ma-0"
                    >
                      <v-col cols="12" md="6">
                        <h3 class="p-0">
                          {{ business.shortName }}
                        </h3>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3 class="p-0">
                          {{ business.tin ? business.tin : "RTN no agregado" }}
                        </h3>
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="$store.state.user.type == 'sudo'"
                      class="pa-0 ma-0 pt-2 mt-2"
                    >
                      <v-col cols="12" md="6">
                        <h3>Nombre del comercio</h3>
                        <input
                          class="control-input"
                          type="text"
                          placeholder="Ingrese el nombre del comercio"
                          v-model="business.shortName"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3>RTN</h3>
                        <input
                          class="control-input control-input-number"
                          @keydown="isNumber($event)"
                          type="number"
                          placeholder="Ingrese el RTN"
                          v-model="business.tin"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="pa-0 ma-0 pt-2 mt-2">
                      <v-col cols="12" md="6">
                        <h3>Persona de contacto</h3>
                        <input
                          class="control-input"
                          type="text"
                          placeholder="Persona a contactar en el negocio"
                          v-model="business.contactName"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3>Teléfono</h3>
                        <input
                          class="control-input"
                          placeholder="Número de persona de contacto"
                          v-model="business.phone"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="pa-0 ma-0 pt-4 mt-4">
                      <v-col cols="12" md="6">
                        <h3>Dirección</h3>
                        <textarea
                          v-model="business.zone"
                          placeholder="Dirección exacta del negocio"
                          class="control-input"
                          rows="3"
                        >
                        </textarea>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3>Descripción</h3>
                        <textarea
                          @keypress="
                            maxLength($event, business.description, 300)
                          "
                          maxlength="300"
                          placeholder="Descripción corta del negocio"
                          v-model="business.description"
                          class="control-input"
                          rows="3"
                        >
                        </textarea>
                      </v-col>
                    </v-row>

                    <v-row
                      class="pa-0 ma-0 pt-4 mt-4"
                      v-if="$store.state.user.type == 'sudo'"
                    >
                      <v-col cols="12" md="6">
                        <h3>Título sección envío gratis</h3>
                        <input
                          class="control-input"
                          type="text"
                          v-model="business.freeShippingSectionTitle"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <h3>Subtítulo sección envío gratis</h3>
                        <input
                          class="control-input"
                          type="text"
                          v-model="business.freeShippingSectionSubtitle"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="pa-0 ma-0 pt-4 mt-4">
                      <v-col cols="12" md="4">
                        <h3>Propina Sugerida</h3>
                        <p>porcentual</p>
                        <input
                          class="control-input"
                          type="text"
                          v-model="business.qrTipAmount"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-switch
                          v-model="business.allowTip"
                          label="Activar Propina"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>

                  <!-- QR OPTIONS -->
                  <qr-options
                    ref="qrOptions"
                    :business="business"
                    v-if="!loading"
                  />

                  <v-container fluid>
                    <h1 class="font-weight-black">Imagen de Promoción</h1>
                    <p>imagen aparecera al iniciar el menu</p>

                    <v-divider class="mb-5"></v-divider>
                    <v-row class="pa-0 ma-0 mt-4" align="center">
                      <v-col cols="6" md="6">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <div
                              v-on="on"
                              style="position: relative; cursor: pointer"
                            >
                              <v-img
                                class="mt-4 border-radius"
                                max-height="300px"
                                max-width="300px"
                                @click="showFilePicker"
                                :src="qrImgPromo.imgAds || defaultImage"
                              >
                              </v-img>
                            </div>
                          </template>
                          <span>Clic para Cambiar</span>
                        </v-tooltip>
                        <input
                          @change="setQrImage"
                          ref="file"
                          type="file"
                          style="display: none"
                          accept="image/*"
                        />
                        <span class="text-caption"
                          >imagen - 1080px x 1080px, 150ppi
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3>tiempo de retardo (Segundos)</h3>
                        <input
                          class="control-input"
                          type="text"
                          v-model="qrImgPromo.timeDelay"
                        />
                        <v-switch
                          v-model="qrImgPromo.active"
                          label="Activar imagen de Promoción"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container v-if="itemQrTypesOrdersPanel">
                    <h1 class="font-weight-black" >
                      Configuración de tipos de entregas
                    </h1>
                    <v-row class="mt-4">
                      <v-col>
                        <v-autocomplete
                          v-model="business.qrTypesOrders"
                          :items="itemQrTypesOrdersPanel"
                          outlined
                          dense
                          chips
                          style="border-radius: 15px"
                          small-chips
                          label="Selecciona los tipos de entregas"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container>
                    <h1 class="font-weight-black">
                      Configuración de métodos de pago
                    </h1>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-switch
                          label="botón de pago en efectivo"
                          v-model="business.qrBtnCash"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-switch
                          label="botón de pago en línea"
                          v-model="business.qrBtnOnline"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container fluid>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="12">
                        <v-divider></v-divider>
                        <v-row justify="end">
                          <v-btn
                            class="save-btn"
                            @click="updateBusinessInformation"
                            color="primary"
                            dark
                            elevation="0"
                          >
                            Guardar
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
    <v-dialog
      persistent
      v-model="modalUpdateImgPromo"
      v-if="modalUpdateImgPromo"
      max-width="610px"
    >
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline"> Vista previa </span>

          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalUpdateImgPromo = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col v-if="qrSrc" cols="12">
                <v-row justify="center">
                  <img class="preview-image" :src="qrSrc" />
                </v-row>
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider> -->
            <v-row justify="end" class="upload-btn" align="center">
              <v-btn
                @click.prevent="saveImagePromo"
                :loading="uploading"
                class="mt-4 save-btn"
                color="primary"
                dark
              >
                Subir
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalUploadImage" v-if="imgSrc" max-width="610px">
      <v-overlay class="loading-center" :value="save">
        <!-- <lottie
          :options="defaultOptions"
          :height="300"
          :width="400"
          v-on:animCreated="handleAnimation"
        /> -->
        <v-progress-circular
          :width="3"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">
            Vista previa imágen de
            {{ imageType === "banner" ? "portada" : "logo" }}
          </span>

          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            elevation="0"
            @click="modalUploadImage = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col v-if="imgSrc" cols="12">
                <v-row justify="center">
                  <img class="preview-image" :src="imgSrc" />
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="end" class="upload-btn" align="center">
              <v-btn
                @click.prevent="saveImage"
                class="mt-4 save-btn"
                color="primary"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
// import SudoGeneralOptions from "./General-sudo-options";
import axios from "axios";
import ledgerMixin from "@/mixins/ledgerMixin";

import qrOptions from "./qrConfiguration.vue";

export default {
  name: "general",
  components: {
    lottie: Lottie,

    // SudoGeneralOptions,
    qrOptions,
  },
  mixins: [lottieSettings, ledgerMixin],
  data() {
    return {
      modal: null,
      dialogModalTags: false,
      business: null,
      snackbar: false,
      snackbarText: null,
      color: "green",
      timeout: 3000,
      uploading: false,
      modalUploadImage: false,
      imagePromo: "",
      modalUpdateImgPromo: false,
      categories: [],
      qrImgPromo: {
        active: false,
        timeDelay: 1000,
        imgAds:
          "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
      },
      defaultImage:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",

      cities: [],
      loading: true,
      save: false,
      imageType: null,
      suscriptionBusiness: null,
      allTags: [],
      imgSrc: "",
      imageFile: null,
      imageDownloadLoading: false,
      exit: false,
      typeOrder: [
        {
          value: "dineIn",
          text: "Dine in (Ordenar en mesa)",
          icon: "mdi-table-chair",
          show: false,
        },
        {
          value: "pickUp",
          text: "Pickup (Recoger en tienda)",
          icon: "mdi-car-back",
          show: false,
        },
        {
          value: "regular",
          text: "Delivery (A domicilio)",
          icon: "mdi-moped",
          show: false,
        },
        {
          value: "room",
          text: "Room Services (A la habitación)",
          icon: "mdi-moped",
          show: false,
        },
      ],
    };
  },
  mounted() {
    // if (!this.selectedBusiness) {
    //   return this.$router.push({ path: "/" });
    // }
    this.$store.state.visibleSearch = false;
    this.getBusinessData();
  },
  watch: {
    selectedBusiness() {
      if (this.suscriptionBusiness) {
        this.suscriptionBusiness();
      }
      this.business = null;
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    itemQrTypesOrdersPanel() {
      var objLit = {
        regular: { value: "regular", text: "Delivery" },
        dineIn: { value: "dineIn", text: "Servir en mesa" },
        pickUp: { value: "pickUp", text: "Recogida" },
        room: { value: "room", text: "Servir en la habitación" },
      };
      if (this.business.qrTypesOrdersPanel) {
        return this.business.qrTypesOrdersPanel.map((item) => {
        return objLit[item];
      });
      }else{
        return []
      }
      
    },
  },
  methods: {
    maxLength(evt, field, length) {
      if (field.length >= length) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setQrImage(e) {
      this.imagePromo = e.target.files[0];

      if (this.imagePromo.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.qrSrc = event.target.result;
          this.modalUpdateImgPromo = true;
        };

        reader.readAsDataURL(this.imagePromo);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },
    showFilePicker() {
      this.$refs.file.click();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    remove(item) {
      this.business.tags.splice(this.allTags.indexOf(item), 1);
      this.business.tags = [...this.business.tags];
    },
    setImage(e) {
      this.imageFile = e.target.files[0];

      if (this.imageFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.modalUploadImage = true;
        };

        reader.readAsDataURL(this.imageFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },
    successUpload: function () {
      this.modalUploadImage = false;
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Imágen actualizada";
    },
    removeTag: function (item) {
      this.business.tags = this.business.tags.filter((tag) => tag != item);
    },
    selectImage: function (imageType) {
      this.imageType = imageType;
      this.$refs.input.click();
    },
    saveImagePromo() {
      this.uploading = true;

      let extension = this.imagePromo.type.split("/")[1];

      var uploadTask = fb
        .app()
        .storage(`${process.env.VUE_APP_EL_OCHO_BUCKETS}-businesses`)
        .ref()
        .child(`${this.selectedBusiness[".key"]}/qrImgPromo.${extension}`)
        .put(this.imagePromo);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.uploading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await db
              .collection("businesses")
              .doc(this.selectedBusiness[".key"])
              .update({
                qrImgPromo: {
                  active: this.qrImgPromo.active,
                  timeDelay: this.qrImgPromo.timeDelay,
                  imgAds: downloadURL,
                },
              });

            this.uploading = false;
            this.modalUpdateImgPromo = false;
            this.save = false;
            this.snackbar = true;
            this.snackbarText = "Promo actualizada correctamente";
          });
        }
      );
    },
    saveImage() {
      this.uploading = true;

      let bucket =
        this.imageType == "brand" || this.imageType == "banner"
          ? `${process.env.VUE_APP_EL_OCHO_BUCKETS}-businesses`
          : null;

      if (bucket) {
        this.save = true;
        fb.app()
          .storage(bucket)
          .ref()
          .child(`${this.selectedBusiness[".key"]}/${this.imageType}`)
          .put(this.imageFile)
          .then(() => {
            this.successUpload();
          })
          .catch(() => {
            this.save = false;
            this.snackbar = true;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
          });
      }
    },
    closeModalUploadImage: function () {
      this.modalUploadImage = false;
    },
    updateCascadeBusiness(businessId, business) {
      db.collection(`cities/${business.city}/sections`)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let sectionId = doc.id;
            let section = doc.data();

            let items = section.target.filter((doc) => {
              return doc.business == businessId;
            });

            items.forEach((elementUpdate) => {
              elementUpdate.isBusinessActive = business.active;
              elementUpdate.closed = business.closed;
              elementUpdate.isForceClosed = business.isForceClosed;
              elementUpdate.isComingSoon = business.isComingSoon;
            });

            db.collection(`cities/${business.city}/sections`)
              .doc(sectionId)
              .update({
                target: section.target,
              })
              .catch((error) => {
                throw error;
              });
          });
        });
    },
    updateBusinessInformation: function () {
      let businessInfo = {
        shortName: this.business.shortName ? this.business.shortName : "",
        tin: this.business.tin ? this.business.tin : "",
        zone: this.business.zone ? this.business.zone : "",
        contactName: this.business.contactName ? this.business.contactName : "",
        isForceClosed: this.business.hasOwnProperty("isForceClosed")
          ? this.business.isForceClosed
          : false,
        closed: this.business.hasOwnProperty("closed")
          ? this.business.closed
          : false,
        allowTip: this.business.allowTip || false,
        qrBtnCash: this.business.qrBtnCash || false,
        qrBtnOnline: this.business.qrBtnOnline || false,
        qrTypesOrders: this.business.qrTypesOrders,
        qrTipAmount: this.business.qrTipAmount || 0,
        description: this.business.description ? this.business.description : "",
        phone: this.business.phone ? this.business.phone : "",
        city: this.business.city ? this.business.city : "",
        cities: this.business.cities ? this.business.cities : [],
        qrImgPromo: this.qrImgPromo,
        keywords: this.business.keywords ? this.business.keywords : [],
        ochoCategories: this.business.ochoCategories
          ? this.business.ochoCategories
          : [],
        promoSectionTitle: this.business.promoSectionTitle
          ? this.business.promoSectionTitle
          : "",
        promoSectionSubtitle: this.business.promoSectionSubtitle
          ? this.business.promoSectionSubtitle
          : "",
        qrClosed: this.business.hasOwnProperty("qrClosed")
          ? this.business.qrClosed
          : false,
        qrBackgroundType: this.business.hasOwnProperty("qrBackgroundType")
          ? this.business.qrBackgroundType
          : false,
        qrBackgroundColor: this.business.qrBackgroundColor || "",
        qrThemeColor: this.business.qrThemeColor || "",
        qrDelvieryFees: this.$refs.qrOptions
          ? this.$refs.qrOptions.qrDelvieryFees.filter(
              (item) => item.name && item.cost
            )
          : [],
      };

      this.save = true;

      let prevValue = this.selectedBusiness;
      console.debug(businessInfo);
      var businessRef = db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"]);

      businessRef
        .update(businessInfo)
        .then(() => {
          this.save = false;
          this.snackbar = true;
          this.snackbarText = "Información actualizada correctamente.";

          this.createLedger({
            source_id: this.selectedBusiness[".key"],
            source_type: "businesses",
            currentValue: this.selectedBusiness,
            prevValue: prevValue,
          });
        })
        .catch(() => {
          this.save = false;
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente";
        });
    },
    async getBusinessData() {
      // if (!this.selectedBusiness) this.$router.push({ path: "/" });

      this.suscriptionBusiness = db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .onSnapshot(async (doc) => {
          if (doc.exists) {
            this.business = doc.data();
            if (this.business.qrImgPromo) {
              this.qrImgPromo = this.business.qrImgPromo;
            }
            this.loading = false;
          }
        });
    },
    downloadImage(business, type = "logo") {
      this.imageDownloadLoading = true;
      let setImage =
        type === "logo" ? business.logo.original : business.cover.original;
      axios.get(setImage, { responseType: "blob" }).then((response) => {
        const blob = new Blob([response.data], { type: "image/png" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${business.shortName.trim()}-${type}`;
        link.click();
        URL.revokeObjectURL(link.href);
        this.imageDownloadLoading = false;
      });
      this.exit = false;
    },
  },
  destroyed() {
    if (this.suscriptionBusiness) this.suscriptionBusiness();
  },
};
</script>

<style scoped>
.logo,
.cover {
  margin-top: 20px;
  cursor: pointer;
}
.save-btn {
  margin-top: 20px;
}
.add-tag {
  background-color: rgba(0, 0, 0, 0.37);
}
.add-tag-ico {
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 5px;
}
.btn-modal-add-tags {
  justify-content: center;
}
.upload-container .logo {
  width: 10%;
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -8%;
  margin-left: 20px;
}
.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}
.upload-container .banner {
  border: 1px solid black;
  border-radius: 10px;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
}
.upload {
  display: none;
}
.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}
.logo-up:hover {
  border: 2px dashed #f06f3d;
  cursor: pointer;
}
.cover-up:hover {
  border: 1px dashed #f06f3d;
  cursor: pointer;
}
.add-image {
  font-size: 30px;
  color: rgba(128, 128, 128, 0.733) !important;
  cursor: pointer;
}
.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
}
.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  border: 1px solid #80808080;
  box-shadow: 0 0 0 0;
}

/* h1 {
  color: #f06f3d!important;
} */
</style>
