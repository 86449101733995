<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-col class="mt-0" cols="12">
        <h1 class="font-weight-black">Configuración de QR</h1>
        <v-divider class="mb-5"></v-divider>
      </v-col>
      <!-- <v-col cols="12" md="12" sm="12" class="my-5">
        <v-btn
          @click="qrDialog = true"
          class="mt-0"
          block
          elevation="0"
          height="80px"
          color="primary"
        >
          <span style="font-size: 30px"><b>Generar QR</b> </span>
        </v-btn>
      </v-col> -->
      <v-col v-if="$store.state.user.type == 'sudo'" cols="12" md="4">
        <h3>Código</h3>
        <input class="control-input" type="text" v-model="business.qrCode" />
      </v-col>

      <v-col cols="12" md="6">
        <h3>Color de tema (color principal)</h3>
        <input
          class="control-input"
          type="color"
          style="height: 40px"
          v-model="business.qrThemeColor"
        />
      </v-col>
      <v-col cols="12" md="6">
        <h3>Color de fondo (en caso no utilice una imagen)</h3>
        <input
          class="control-input"
          type="color"
          style="height: 40px"
          v-model="business.qrBackgroundColor"
        />
      </v-col>

      <v-col v-if="$store.state.user.type == 'sudo'" cols="12" md="4">
        <h3>Porcentaje</h3>
        <input
          @keypress="isNumber($event)"
          class="control-input control-input-number"
          type="number"
          v-model="business.qrPercentage"
        />
      </v-col>

      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" md="12">
            <h3>Imagen de fondo del menú</h3>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on" style="position: relative; cursor: pointer">
                  <i
                    v-if="business.qrBackgroundImage"
                    @click="qrImageDialog = true"
                    class="fas fa-expand-alt"
                  ></i>
                  <v-img
                    class="mt-4 border-radius"
                    max-height="300px"
                    @click="showFilePicker"
                    :src="business.qrBackgroundImage || defaultImage"
                  >
                  </v-img>
                </div>
              </template>
              <span>Clic para Cambiar</span>
            </v-tooltip>
          </v-col>

          <input
            @change="setQrImage"
            ref="file"
            type="file"
            style="display: none"
            accept="image/*"
          />
        </v-row>
      </v-col>

      <!-- <v-row class="pa-0 ma-0 mt-5">
        <v-col cols="12">
          <h3>Tarifas de delivery</h3>
          <v-divider class="mb-5"></v-divider>
        </v-col>

        <v-row
          class="fee ml-3"
          v-for="(fee, index) of qrDelvieryFees"
          :key="index"
        >
          <v-col cols="12" md="6">
            <h3>Zona</h3>
            <input
              class="control-input"
              type="text"
              placeholder="Zona de entrega"
              v-model="fee.name"
            />
          </v-col>

          <v-col cols="12" md="3">
            <h3>Valor del delivery</h3>
            <input
              class="control-input control-input-number"
              @keypress="isNumber($event, fee.cost)"
              type="number"
              placeholder="Valor del delivery"
              v-model.number="fee.cost"
            />
          </v-col>

          <v-col cols="3">
            <v-btn
              @click="removeQRFee(index)"
              small
              block
              color="red darken-4"
              class="white--text mt-12 pa-4"
            >
              Eliminar
            </v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" sm="12" md="12">
          <a  @click="addQRFees()"
            >Agregar tarifa de delivery</a
          >
        </v-col>
      </v-row> -->

      <v-col cols="12" sm="12" md="12">
        <v-row>
          <v-col v-if="$store.state.user.type == 'sudo'" cols="12" md="4">
            <v-switch v-model="business.qrActive" label="Activar QR"></v-switch>
          </v-col>
          <v-col cols="12" md="4">
            <v-switch
              v-model="business.qrClosed"
              label="Cerrar Comercio"
            ></v-switch>
          </v-col>

          <v-col cols="12" md="4">
            <v-switch
              v-model="business.qrBackgroundType"
              label="Usar imagen de fondo"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog width="900px" v-model="qrImageDialog">
      <viewImage
        :url="business.qrBackgroundImage"
        @cancel="qrImageDialog = false"
      ></viewImage>
    </v-dialog>

    <v-dialog
      persistent
      v-model="modalUploadQr"
      v-if="modalUploadQr"
      max-width="610px"
    >
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline"> Vista previa </span>

          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalUploadQr = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col v-if="qrSrc" cols="12">
                <v-row justify="center">
                  <img class="preview-image" :src="qrSrc" />
                </v-row>
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider> -->
            <v-row justify="end" class="upload-btn" align="center">
              <v-btn
                @click.prevent="saveImage"
                :loading="uploading"
                class="mt-4 save-btn"
                color="primary"
                dark
              >
                Subir
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="qrDialog" v-if="qrDialog" max-width="60%">
      <generate-qr @cancel="qrDialog = false" :code="business.qrCode" />
    </v-dialog>
  </v-container>
</template>

<script>
import viewImage from "@/components/viewImage";
import { mapState } from "vuex";
import { db, fb } from "@/firebase";
import generateQr from "@/components/generateQR";

export default {
  name: "qr-options",
  props: ["business"],
  components: {
    viewImage,
    generateQr,
  },
  data() {
    return {
      //   loading: true,
      snackbar: false,
      snackbarText: "",
      gettingTags: true,
      imageQRFile: "",
      qrImageDialog: false,
      modalUploadQr: false,
      qrSrc: "",
      uploading: false,
      qrDialog: false,
      defaultImage:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",

      qrDelvieryFees: [{ name: "", cost: 80 }],
    };
  },
  computed: {
    ...mapState(["selectedBusiness"]),
  },

  methods: {
    addFees() {
      this.extraFees.push({
        extraFeeIsFixed: "",
        extraFeeName: "",
        extraFeeSum: "",
        active: false,
      });
    },
    saveImage() {
      this.uploading = true;

      let extension = this.imageQRFile.type.split("/")[1];

      var uploadTask = fb
        .app()
        .storage(`${process.env.VUE_APP_EL_OCHO_BUCKETS}-businesses`)
        .ref()
        .child(`${this.selectedBusiness[".key"]}/qr.${extension}`)
        .put(this.imageQRFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.uploading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await db
              .collection("businesses")
              .doc(this.selectedBusiness[".key"])
              .update({
                qrBackgroundImage: downloadURL,
              });

            this.uploading = false;
            this.modalUploadQr = false;
          });
        }
      );
    },

    showFilePicker() {
      this.$refs.file.click();
    },

    setQrImage(e) {
      this.imageQRFile = e.target.files[0];

      if (this.imageQRFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.qrSrc = event.target.result;
          this.modalUploadQr = true;
        };

        reader.readAsDataURL(this.imageQRFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },

    addQRFees() {
      this.qrDelvieryFees.push({
        name: "",
        cost: 80,
      });
    },

    removeQRFee(index) {
      this.qrDelvieryFees = this.qrDelvieryFees.filter((item, i) => i != index);
    },

    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  async mounted() {
    this.qrDelvieryFees =
      this.business.qrDelvieryFees && this.business.qrDelvieryFees.length
        ? this.business.qrDelvieryFees
        : this.qrDelvieryFees;
  },
};
</script>


<style scoped>
.fee {
  background-color: #e6e6e680;
  border: 1px solid #ffffff7a;
  /* border-radius: 5%; */
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.fa-expand-alt {
  font-size: 25px;
  color: #ffffff;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  background-color: #b1b1b1a6;
  padding: 5px;
}

.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}
.border-radius {
  border-radius: 12px !important;
}
</style>
